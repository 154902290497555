import { gql } from '@apollo/client';
import styled from 'styled-components';
import { HighlightFragment, HighlightTransformFragment } from '../generated/graphql';
import Link from './Link';
import { Box } from '@rebass/grid';
import Title from '@oberoninternal/travelbase-ds/components/primitive/Title';
import React, { FC } from 'react';
import Skeleton from 'react-loading-skeleton';
import { highlightTransformToProps } from '../constants/imageTransforms';
import Img from '@oberoninternal/travelbase-website/dist/components/Img';

export const query = gql`
    fragment Highlight on EntryInterface {
        title
        uri
        __typename
        ... on page_Entry {
            heroImage {
                ...HighlightTransform
            }
        }
        ... on article_Entry {
            newsHeroImage {
                ...HighlightTransform
            }
        }
    }
`;

const Highlights: FC<React.PropsWithChildren<{ highlights: HighlightFragment[]; loading?: boolean }>> = ({
    highlights,
    loading = false,
}) => (
    <Container>
        {!loading
            ? highlights.map((highlight, key) => {
                  let image: HighlightTransformFragment | undefined | null;
                  if (highlight?.__typename === 'article_Entry') {
                      image = highlight?.newsHeroImage?.[0];
                  }
                  if (highlight?.__typename === 'page_Entry') {
                      image = highlight?.heroImage?.[0];
                  }
                  return (
                      highlight?.uri && (
                          <Link href={`${highlight.uri}`} passHref key={key}>
                              <Item>
                                  {image && (
                                      <Thumbnail>
                                          <Img loading="lazy" {...highlightTransformToProps(image)} />
                                      </Thumbnail>
                                  )}
                                  <Box mx={[4, 5]}>
                                      <Title variant={'tiny'} elementType={'h3'}>
                                          {highlight.title}
                                      </Title>
                                  </Box>
                              </Item>
                          </Link>
                      )
                  );
              })
            : Array(4)
                  .fill(null)
                  .map((_, i) => <StyledSkeleton key={i} />)}
    </Container>
);

export default Highlights;

const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 1.2rem;

    h3 {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        overflow: hidden;
        font-weight: 500;
        color: inherit;
    }

    a {
        text-decoration: none;
        &:hover {
            text-decoration: underline;
        }
    }

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.s}) {
        grid-template-columns: 1fr 1fr;
    }

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.l}) {
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 3.2rem;
    }
`;
const Item = styled.a`
    border: 0.1rem solid ${({ theme }) => theme.colors.neutral['20']};
    color: ${({ theme }) => theme.colors.primary['80']};
    box-shadow: 0 0.2rem 0.4rem 0 rgba(59, 118, 160, 0.12);
    transition: color 0.2s ease-in-out;
    border-radius: 0.8rem;
    display: flex;
    align-items: center;
    cursor: pointer;
    overflow: hidden;
    min-height: 6.4rem;
    :hover {
        color: ${({ theme }) => theme.colors.primary['60']};
    }
    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.xl}) {
        min-height: 8rem;
    }
`;

const StyledSkeleton = styled(Skeleton)`
    min-height: 6.4rem;

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.xl}) {
        min-height: 8rem;
    }
`;

const Thumbnail = styled.div`
    width: 6.4rem;
    min-width: 6.4rem;
    height: 6.4rem;
    position: relative;

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.xl}) {
        width: 8rem;
        min-width: 8rem;
        height: 8rem;
    }
`;
