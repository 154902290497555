import styled from 'styled-components';
import React, { FC } from 'react';
import { ActionFragment, HomeDocument, HomeFragment, HomeQuery, HomeQueryVariables } from '../../generated/graphql';
import { useRouter } from 'next/router';
import Seo from '../Seo';
import { heroTransformToProps } from '../../constants/imageTransforms';
import { Box } from '@rebass/grid';
import Title from '@oberoninternal/travelbase-ds/components/primitive/Title';
import USPs from '../USPs';
import { gql } from '@apollo/client';
import Hero from '../Hero';
import Page from '@oberoninternal/travelbase-website/dist/components/Page';
import useAnalyticsContentCategory from '@oberoninternal/travelbase-website/dist/hooks/analytics/useAnalyticsContentCategory';
import { FormattedMessage } from 'react-intl';
import Card from '../Card';
import ContentWrapper from '@oberoninternal/travelbase-website/dist/components/ContentWrapper';
import UpcomingReservations from '../UpcomingReservations';
import Highlights from '../Highlights';
import Action from '../Action';
import { ItemList, ListItem, WithContext } from 'schema-dts';
import { GetStaticProps } from 'next';
import initApolloClient, { PreviewData } from '../../initApolloClient';
import addGlobalQuery, { addGlobalProps } from '../../utils/addGlobalQuery';
import { addApolloState } from '@oberoninternal/travelbase-website/dist/createApolloClient';
import { DEFAULT_REVALIDATION_TIME } from '../../constants/revalidationTime';
import SearchAndBook from '../SearchAndBook';
import getCurrentBaseUrl from '../../utils/getCurrentBaseUrl';
import VideoCard from '../blocks/VideoCard';
import Head from 'next/head';

export const query = gql`
    query Home($lang: String) {
        entry(uri: ["__home__"], site: [$lang]) {
            ...Home
        }
    }

    fragment Home on homepage_Entry {
        heroImage {
            ...HeroTransform
        }
        title
        video
        homeVideoEnabled
        homeVideoTitle
        homeVideoDescription
        homeHighlightsTitle
        homeHighlights {
            ...Highlight
            ... on page_Entry {
                settingsSeoTitle
                settingsMetaDescription
            }
        }
        homeCurrent {
            ...Card
        }
        action {
            ...Action
        }
        settingsCookieConsent
        settingsMetaDescription
        settingsSeoTitle
        header

        localized {
            url
            language
        }
    }
`;
const Home: FC<React.PropsWithChildren<{ entry: HomeFragment }>> = ({ entry }) => {
    const { locale } = useRouter();
    useAnalyticsContentCategory('homepage');

    const {
        title,
        homeHighlightsTitle,
        homeHighlights,
        homeCurrent,
        settingsSeoTitle,
        settingsMetaDescription,
        heroImage,
        localized,
        action: actions,
        header,
        video,
        homeVideoEnabled,
        homeVideoTitle,
        homeVideoDescription,
    } = entry;

    const homeHeroImage = heroImage?.[0];

    const schema: WithContext<ItemList> = {
        '@context': 'https://schema.org',
        '@type': 'ItemList',
        itemListElement: homeHighlights?.map((highlight, i) => {
            const url = `${getCurrentBaseUrl(locale)}/${highlight?.uri}`;
            const listItem: ListItem = {
                '@type': 'ListItem',
                position: i,
                url: url ?? '',
                name: highlight?.title ?? '',
            };

            if (highlight?.__typename === 'page_Entry') {
                if (highlight.settingsMetaDescription) {
                    listItem.description = highlight.settingsMetaDescription;
                }
                if (highlight.settingsSeoTitle) {
                    listItem.name = highlight.settingsSeoTitle;
                }
            }

            return listItem;
        }),
    };

    const action = actions?.[0];
    return (
        <Page>
            <Head>
                {/* eslint-disable-next-line react/no-danger */}
                <script type="application/ld+json" dangerouslySetInnerHTML={{ __html: JSON.stringify(schema) }} />
            </Head>
            <Seo
                settingsSeoTitle={settingsSeoTitle ?? title ?? ''}
                settingsMetaDescription={settingsMetaDescription ?? ''}
                image={homeHeroImage?.ogImageUrl ?? undefined}
                alternates={localized}
            />
            <>
                {homeHeroImage && (
                    <HeroAdj {...heroTransformToProps(homeHeroImage)}>
                        <TitleWrapper>
                            <ContentWrapper>
                                <Flex>
                                    <FlexTitle>
                                        <HeroTitle variant="large">{header}</HeroTitle>
                                    </FlexTitle>
                                    <SearchAndBook />
                                </Flex>
                            </ContentWrapper>
                        </TitleWrapper>
                    </HeroAdj>
                )}

                {action && (
                    <Action
                        data={
                            // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
                            action as ActionFragment
                        }
                    />
                )}

                {homeHighlights && homeHighlights.length > 0 && (
                    <ContentWrapper>
                        <Box mt={[6, 8]}>
                            <Title variant="large" elementType={'h2'}>
                                {homeHighlightsTitle}
                            </Title>
                            <Box mt={[4, 7]} mb={[8]}>
                                <Highlights
                                    highlights={
                                        homeHighlights
                                            .filter((hit): hit is NonNullable<typeof hit> => !!hit)
                                            .map(hit => ({ ...hit, uri: `/${hit.uri}` })) ?? []
                                    }
                                />
                            </Box>
                        </Box>

                        <div className="sqzly-personalisations" />
                    </ContentWrapper>
                )}
                {homeCurrent && homeCurrent.length > 0 && (
                    <ContentWrapper>
                        <Box mt={[6, 8]}>
                            <Title variant="large" elementType={'h2'}>
                                <FormattedMessage defaultMessage="Actueel" />
                            </Title>
                        </Box>
                        <Box mt={[4, 7]} mb={[4, 8]} pb={[4, 8]}>
                            {homeVideoEnabled && (
                                <Cards>
                                    <VideoCard
                                        videoUrl={video ?? ''}
                                        videoTitle={homeVideoTitle ?? ''}
                                        videoDescription={homeVideoDescription ?? ''}
                                    />
                                    {homeCurrent.slice(0, 2).map(card => card && <Card key={card.uri} item={card} />)}
                                </Cards>
                            )}
                            {!homeVideoEnabled && (
                                <Cards>{homeCurrent.map(card => card && <Card key={card.uri} item={card} />)}</Cards>
                            )}
                        </Box>
                    </ContentWrapper>
                )}

                <UpcomingReservations title={<FormattedMessage defaultMessage="Je aankomende vakantie" />} />

                <USPs />
            </>
        </Page>
    );
};

export const getStaticProps: GetStaticProps = async context => {
    if (context.locale === 'default') return { props: {} };

    const { previewData } = context;
    const client = initApolloClient(context.locale as string, { previewData: previewData as PreviewData });
    const lang = context.locale as string;

    const { data } = await client.query<HomeQuery, HomeQueryVariables>({
        query: HomeDocument,
        variables: { lang },
    });

    const { entry } = data;

    if (!entry) {
        return {
            notFound: true,
        };
    }

    const suppressCookieConsent = !((data.entry as { settingsCookieConsent?: boolean }).settingsCookieConsent ?? false);

    return {
        ...addApolloState(
            client,
            (await addGlobalQuery(
                {
                    props: {
                        entry: data.entry,
                        ...addGlobalProps(data),
                        suppressCookieConsent,
                    },
                },
                context.locale
            )) as { props: Record<string, unknown> }
        ),
        revalidate: DEFAULT_REVALIDATION_TIME,
    };
};

const HeroAdj = styled(Hero)`
    @media screen and (max-width: ${({ theme }) => theme.mediaQueries.xs}) {
        max-height: 100%;
        min-height: 56rem;
    }

    @media screen and (max-width: ${({ theme }) => theme.mediaQueries.s}) {
        min-height: calc(100vh + 5.6rem);
    }

    @media screen and (max-width: ${({ theme }) => theme.mediaQueries.m}) {
        min-height: 64rem;
    }

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        min-height: calc(41.4rem + var(--wrapperPadding));
        max-height: 65vh;
    }

    overflow: visible;
`;

const FlexTitle = styled.div`
    display: flex;
    align-items: center;

    @media screen and (max-width: ${({ theme }) => theme.mediaQueries.s}) {
        padding: 0 var(--wrapperPadding);
    }
`;

const Flex = styled.div`
    display: flex;
    align-items: start;
    flex-direction: column;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    padding: 3rem 0 0 0;
    padding: 0 var(--wrapperPadding);
    justify-content: center;

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        position: relative;
        padding: 0;
    }
`;

const Cards = styled.div`
    display: grid;
    position: relative;
    grid-gap: 3.2rem;
    grid-template-columns: 1fr;

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        grid-template-columns: 1fr 1fr 1fr;
    }
`;
const TitleWrapper = styled.div`
    z-index: 1;
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
`;
const HeroTitle = styled(Title)`
    white-space: pre-line;
    color: ${({ theme }) => theme.colors.neutral['0']};
    text-shadow: 0 2px 12px rgba(7, 7, 23, 0.32);
    font-size: 3.2rem;
    text-align: center;
    font-weight: bold;

    @media screen and (min-width: ${({ theme }) => theme.mediaQueries.m}) {
        font-size: 6rem;
        line-height: 6.8rem;
    }
`;
export default Home;
