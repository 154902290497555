import { gql } from '@apollo/client';
import styled from 'styled-components';
import React, { FC } from 'react';
import ContentWrapper from '@oberoninternal/travelbase-website/dist/components/ContentWrapper';
import { Box, Flex } from '@rebass/grid';
import { ActionFragment } from '../generated/graphql';
import Title from '@oberoninternal/travelbase-ds/components/primitive/Title';
import HtmlMapper from 'react-html-map';
import tagMap from '../constants/tagMap';
import LinkField from './LinkField';
import Button from '@oberoninternal/travelbase-ds/components/action/Button';
import { FormattedMessage } from 'react-intl';

export const fragment = gql`
    fragment Action on action_Entry {
        title
        description
        button {
            ...LinkField
        }
    }
`;

const Action: FC<React.PropsWithChildren<{ data: ActionFragment }>> = ({ data }) => (
    <Container>
        <ContentWrapper>
            <Flex py={5} alignItems={[null, null, 'center']} flexDirection={['column', null, 'row']}>
                <Box style={{ flex: 1 }} mr={[null, null, 3]} mb={[5, null, 0]}>
                    <Title elementType="h1" variant="small">
                        {data.title}
                    </Title>
                    <StyledBox>
                        {data.description && <HtmlMapper html={data.description}>{tagMap}</HtmlMapper>}
                    </StyledBox>
                </Box>
                {data.button?.[0] && (
                    <LinkField link={data.button[0]} style={{ textDecoration: 'none' }}>
                        <Button size="large" as="div">
                            {data.button[0]?.text ?? <FormattedMessage defaultMessage="Meer informatie" />}
                        </Button>
                    </LinkField>
                )}
            </Flex>
        </ContentWrapper>
    </Container>
);

export default Action;

const Container = styled.div`
    background: ${({ theme }) => theme.colors.primary[0]};
`;

const StyledBox = styled(Box)`
    > div {
        margin-top: 0.4rem;
    }
`;
